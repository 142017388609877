@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,400;0,500;0,600;0,700;0,800;1,600;1,700&display=swap");
body {
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}
:root {
  --brandColor: #ffc514;
  --backgroundColor: #e5e5e5;
  --acentColor: #1e1e1e;
}
.ant-table-filter-trigger {
  transform: translateY(4px) !important;
}
.anticon {
  transform: translateY(-2px) !important;
}

.status {
  margin: auto !important;
}

.ant-message .anticon {
  margin-right: 8px !important;
  color: white !important;
}

.ant-message-notice-content {
  border-radius: 10px !important;
  font-weight: 600 !important;

  padding: 0 !important;
}

.ant-message-custom-content {
  padding: 0.7rem 1rem !important;
  border-radius: 10px !important;
  color: white !important;
}
.ant-message-error {
  background: #cf3f3c !important;
}

.ant-message-success {
  background: #309442 !important;
}

/*pagination */

.ant-pagination.mini .ant-pagination-item {
  min-width: 30px !important;
  border: none !important;
  margin: auto 0.5rem !important;
}

.ant-table-pagination.ant-pagination {
  padding: 0rem 2rem 1rem 2rem !important;
}
.ant-table-footer {
  padding: 12px 0px !important;
  color: rgba(0, 0, 0, 0.85) !important;
  background: #ffffff !important;
}

.ant-pagination-item-active {
  background-color: #f5f5f5 !important;
  border: none !important;
}

.ant-pagination-item-active a {
  color: #313131 !important;
  padding: 1px !important;
  font-weight: 600 !important;

  opacity: 0.8 !important;
}

.ant-checkbox-inner {
  border: 1px solid grey !important;
  width: 14px !important;
  height: 14px !important;
}

.ant-checkbox-inner::after {
  left: 12.5% !important;
}

.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: none;
}

* {
  margin: 0 !important;
}
code {
  font-family: "Montserrat", sans-serif;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track  */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ant-form-vertical .ant-form-item-label {
  padding: 0 !important;
  height: 45px !important;
}

.ant-form-item {
  margin-bottom: 20px !important;
}

.ant-input {
  color: #000000 !important;
  font-size: 14px !important;
  opacity: 0.9 !important;
}
.ant-input:focus,
.ant-input-focused {
  outline: 0 !important;
  box-shadow: 0 0 0 0px transparent !important;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  outline: 0 !important;

  box-shadow: 0 0 0 0px transparent !important;
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #18c35d !important;
  border-color: #18c35d !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #18c35d !important;
}

.ant-switch-handle::before {
  transform: translateY(-3px) !important;
}

.ant-switch {
  height: 16px !important;
  min-width: 40px !important;
}

.ant-table-tbody > tr > td {
  font-size: 14px !important;
  line-height: 22px !important;
  font-weight: 500 !important;
  color: #1e1e1e !important;
  text-transform: capitalize !important;
  /* text-align: center !important; */

  padding: 8px !important;
}
.ant-table-filter-trigger {

  /* margin: -4px -6px -4px 4px !important;
   */
   transform: translateY(0px) !important;
}

.ant-table-thead > tr > th {
  font-size: 11px !important;
  line-height: 22px !important;
  color: #1e1e1e !important;
  opacity: 0.8 !important;
  text-transform: capitalize !important;
  /* text-align: center !important; */
}

.ant-picker {
  padding: 0.5rem 0.9rem !important;
  border-radius: 10px !important;
  color: #1e1e1e !important;
}

.ant-picker input::placeholder {
  color: #1e1e1e !important;
  font-weight: 500;
}

.ant-tag {
  margin: 2px !important;
  background-color: #e8f3fd !important;
  color: #1d8aef !important;
  border-radius: 10px !important;
  font-size: 14px !important;
  padding: 0.1rem 1rem !important;
}

.ant-tag svg {
  color: #1d8aef !important;
  transform: translate(2px, -2px);
}

.ant-select-selector {
  border: none !important;
}

table tr td.ant-table-selection-column {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.ant-picker-suffix > * {
  transform: translateY(0px) !important;
}

.ant-empty-image svg {
  margin: auto !important;
}
.ant-table-thead {
  box-shadow: 0px 1px 0.1px 0px #f1f1f1 !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  box-shadow: none !important;
}

.ant-switch-checked{

  background-color: #b8ebbe !important;
}

.ant-table-container table {
  padding-bottom: 0.7rem !important;
  padding-top: 0.5rem !important;

}

.ant-checkbox-group-item {
  display: block;
  margin-right: 15px !important;
}

.ant-radio-wrapper{
  margin-right: 15px !important;
}

.ant-input-number-group-wrapper {

  border-bottom: 2px solid #e6e6e6 !important;

}




.ant-input-number-group-addon {

  background-color: transparent !important;
  border:none !important;
  padding: 0 0 0 11px !important;
}
.addonbefore{

  border-right:2px solid #e6e6e6 !important;
}



.ant-table-filter-column {

  display: -webkit-inline-box !important;
  display: -moz-inline-box!important;
  display: -ms-inline-box !important;

}

.ant-checkbox-indeterminate .ant-checkbox-inner::after{

  left:50% !important;
  background-color: #41C35C !important;
}

.anticon-close-circle {

  transform: translateY(0.5px) !important;
  padding: 0.3rem !important;
}

.ant-skeleton-content .ant-skeleton-paragraph > li + li {
  margin-top: 6px !important;
  padding: 20px !important;
  background-color: #ebebeb28 !important;
}

.ant-empty {

  height: 260px !important;
  display: flex !important;
  flex-flow: column !important;
  justify-content: center !important;

}


.react-switch-handle{

  top:-3px !important;

}

.ant-popover-buttons button {

  margin: 0 5px !important;
}


.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input{

  background-color: transparent !important;

}

.ant-message .anticon{

  transform: translateY(-3px) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
 -webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 0) inset !important;
background-color: transparent !important;
box-shadow: 0 0 0 30px rgba(255, 255, 255, 0) inset !important;
/* -webkit-text-fill-color: rgba(51, 51, 51, 0)  !important; */
-webkit-autofill:var(--acentColor) !important;

}



input:internal-autofill-selected {

  background-color: transparent !important;
  -webkit-autofill:var(--acentColor) !important;
}

.ant-picker-year-btn{

  margin-left: 5px !important;
}

.ant-table-filter-dropdown-btns .ant-btn-link {

  display: none !important;



}

.ant-table-filter-dropdown-btns .ant-btn{

  width: 100% !important;
  border-radius: 10px !important;
  font-weight: bold !important;
}